import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
const SOutlet=()=>{

    return(
        
    <Outlet/>

    )
}

export default SOutlet