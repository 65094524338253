import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import { BrowserRouter } from 'react-router-dom';
import AppNavigation from './navigations/AppNavigation';

const  App=()=> {
  return (
   
<AppNavigation/>
   
   
    );
}

export default App;
